<template>
    <div id="page-root">
        <el-config-provider :locale="ko">
            <NuxtLayout>
                <NuxtPage />
            </NuxtLayout>
        </el-config-provider>
    </div>
</template>

<script setup lang="ts">
import ko from "element-plus/dist/locale/ko.mjs";
import { boot } from "~/composables/channelTalk";

onMounted(() => {
    boot(useAuthStore().user);
});
</script>

<style lang="scss">
@import "@/assets/css/common.scss";
@import "@/assets/css/element-plus.scss";
</style>
