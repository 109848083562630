import axios from "axios";

const service = axios.create({
  baseURL: "",
  timeout: 20000,
});

service.interceptors.request.use(
  (config) => {
    if (import.meta.client) {
      const token = useJajakToken().value;
      if (token) {
        config.headers.Authorization = `bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default service;
