import request from '@/utils/request';

const PATH = '/apin/auth';

/* 유저정보 조회
 * @returns {Promise} Promise 객체를 반환합니다.
 * 성공시, { data: { user_info: { ... }, error: null } } 형태의 객체를 반환합니다.
 * 실패시(유정정보 없을 때), { data: error: '유저정보가 존재하지 않음', message: '유저정보를 가져올 수 없습니다.' exception: object } 
 * 실패시(유저서브정보 없을 때), { data: error: '유저서브정보 존재하지 않음', message: '유저서브정보를 가져올 수 없습니다.' exception: object }
 * 실패시(query오류), { data: error: '유저정보 가져오기 쿼리 실패', message: '유저정보를 가져올 수 없습니다.' exception: object }
 */
export const getUserInfo = async () => {
  try {
    // 사용자 정보를 가져옵니다.
    const res = await request({
      url: `${PATH}/initUserInfo`,
      method: 'GET',
    });

    return res;
  } catch (error) {
    console.error(error);
    throw error; // 호출자에게 오류를 전달합니다.
  }
};
