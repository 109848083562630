import moment from 'moment';

export const parseDate = (stringDate: string, format: string) => {
  if (stringDate) {
    const parsingDate = moment(stringDate).format(format || 'YYYY-MM-DD');
    return parsingDate;
  }
  return '-';
};

export const parseConcatNumber = (data: string) => {
  if (data) {
    const filterDate = data.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3');
    return filterDate;
  }
  return data;
};

export const parsefilterDate = (data: any) => {
  if (data) {
    const cloneData = `0${data}`;
    return cloneData;
  }
  return data;
};

export const parseDateTime = (stringDate: string, format: string) => {
  if (stringDate) {
    const parsingDate = moment(stringDate).format(format || 'YYYY.MM.DD HH:mm');
    return parsingDate;
  }
  return '-';
};

export const parseTime = (stringTime: string, format: string) => {
  const parsingDate = moment(stringTime, 'HH:mm:ss').format(format || 'HH:mm');
  return parsingDate;
};

export const parseKrw = (num: number | string) => {
  const number = Number(num);
  /* eslint-disable */
  if (isNaN(number)) return num;
  return Number(number).toLocaleString('ko-KR');
};

export const formatKoreanPrice = (num: number) => {
  const koreanNumbers = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
  const koreanUnit1 = ['', '십', '백', '천'];
  const koreanUnit2 = ['', '만', '억', '조', '경']; // 추가적인 단위 필요 시 여기에 계속 추가할 수 있습니다.

  let result = '';
  const numberStr = String(num);

  for (let i = 0; i < numberStr.length; i++) {
    const digit = parseInt(numberStr.charAt(i));
    const unit1Index = (numberStr.length - 1 - i) % 4;
    const unit2Index = Math.floor((numberStr.length - 1 - i) / 4);
    if (digit !== 0) {
      result += koreanNumbers[digit] + koreanUnit1[unit1Index];
    }
    if (unit1Index === 0 && digit !== 0) {
      result += koreanUnit2[unit2Index];
    }
  }
  return result;
};

export const calendarParseDate = (stringDate: string, format: string) => {
  const parsingDate = moment(stringDate).format(format || 'YYYY.MM.DD');
  return parsingDate;
};

export const doubleDate = (startDate: string, endDate: string) => {
  const dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }
  return dates;
};

export const removeTag = (value: string) => {
  if (value) {
    let filterValue = value.replace(/(<([^>]+)>)/ig, '');
    filterValue = filterValue.replace(/\\n/g, ' ');
    filterValue = filterValue.replace(/&nbsp;/g, ' ');
    filterValue = filterValue.replace(/&gt;/g, '<');
    filterValue = filterValue.replace(/&lt;/g, '>');
    filterValue = filterValue.replace(/&amp;/g, '&');
    return filterValue;
  }
  return value;
};

export const parseProductType = (value: string) => {
  let productType = '';
  if (value === 'T') productType = '타월';
  if (value === 'U') productType = '우산';
  if (value === 'C') productType = '콤보';
  return productType;
};