const JAJAK_TOKEN_KEY = 'jajakToken';
const JAJAK_NEW_SITE_KEY = 'JAJAK_NEW_SITE';

const cookieOptions = (() => {
  if (process.env.NODE_ENV === 'production') {
    return {
      domain: '.jajakjajak.com',
      path: '/',
    };
  }
  return { path: '/' };
})();

export const useJajakToken = () => useCookie(JAJAK_TOKEN_KEY, cookieOptions);

export const useNewSiteToken = () => useCookie(JAJAK_NEW_SITE_KEY, cookieOptions);
