import { getUserInfo } from '~/composables/java/auth';

export default defineNuxtRouteMiddleware(async (to, from) => {
  // SSR에서는 적용하지 않습니다.
  if (import.meta.server) {
    return;
  }

  // 구 쿠키 삭제
  if (process.env.NODE_ENV === 'production') {
    try {
      const jajakToken = useCookie('jajakToken', {
        domain: 'www.jajakjajak.com',
        path: '/',
      });
      jajakToken.value = null;

      const newSiteToken = useCookie('JAJAK_NEW_SITE', {
        domain: 'www.jajakjajak.com',
        path: '/',
      });
      newSiteToken.value = null;
    } catch (e) {
      console.error(e);
    }
  }

  const authStore = useAuthStore();
  const accessToken = useJajakToken();
  if (accessToken.value) {
    if (!authStore.isLogin) {
      try {
        const res = await getUserInfo();
        // @sen.go.kr 계정 부가 처리
        if (res.data.user_info) {
          const email = res.data.user_info.user_email;
          if (email && email.indexOf('@sen.go.kr') > -1) {
            res.data.user_info.user_type = 'suoe';
            res.data.user_info.capability = { value: 'author_ai', level: 6 };
          }
          await authStore.setUser(res.data.user_info);
        } else {
          ElMessage.error(res.data.message || res.data.error || '회원 정보를 불러오지 못했습니다.');
          authStore.resetUser();
          return navigateTo('/login');
        }
        if ((res.data.user_info.user_status == 'pending' || res.data.user_info.user_status == 'oldpending') && to.path != '/join/info') {
          return navigateTo('/join/info');
        }
        if (res.data.assigned_password && to.path !== '/reset-password') {
          return navigateTo('/reset/password');
        }

      } catch (e) {
        authStore.resetUser();
        return navigateTo('/login');
      }
    }
  } else {
    authStore.resetUser();
  }

  if (from.name === 'join-info') {
    if (authStore.user_status == 'pending' || authStore.user_status == 'oldpending') {
      ElMessage.info('약관 동의 및 회원 정보를 입력해야 이용할 수 있습니다.');
      return navigateTo('/join/info');
    }
  }
});
