import { getUserLicense } from '@/composables/java/v2License';
import type { LicenseInfo, Product } from '~/types/jajak-type';

interface Capability {
  level: number;
  value: string;
}

interface IUser {
  agree_ad: boolean,
  capability: Capability, // interface로 변경 (변경: 김정현, 2024-08-29)
  pen_name: string,
  user_avatar: string,
  user_classify: string,
  user_email: string,
  user_email_verified: boolean,
  user_id: string,
  user_login: string,
  user_path: string,
  user_status: string,
  user_type: string,
  isLogin: boolean,
  isAdmin: boolean,
  isLicenseOwner: boolean, // 라이센스 소유자 여부 (추가: 김정현, 2024-08-29)
}

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      user: {
        agree_ad: false,
        capability: {
          level: 0,
          value: '',
        },
        pen_name: '',
        user_avatar: '',
        user_classify: '',
        user_email: '',
        user_email_verified: false,
        user_id: '',
        user_login: '',
        user_path: '',
        user_status: '',
        user_type: '',
        isLogin: false,
        isAdmin: false,
        isLicenseOwner: false, // 라이센스 소유자 여부 (추가: 김정현, 2024-08-29)
      } as IUser,
      license_info: {
        licenseId: 0,
        licenseCode: '',
        licenseDescription: '',
        licenseStatus: '',
        licenseEmail: '',
        startDatetime: '',
        endDatetime: '',
        product: {
          productName: '',
          id: 0,
          productDescription: null,
          productUnit: 0,
          productLevel: '',
          productType: '',
          productPrice: null,
          productStatus: '',
          licenseCapacity: 0
        } as Product,
      } as LicenseInfo, // 라이센스 정보 (추가: 김정현, 2024-11-19)
    }
  },
  getters: {
    pen_name: (state): string => state.user.pen_name,
    user_avatar: (state): string => state.user.user_avatar,
    user_classify: (state): string => state.user.user_classify,
    user_email: (state): string => state.user.user_email,
    user_email_verified: (state): boolean => state.user.user_email_verified,
    user_id: (state): string => state.user.user_id,
    user_login: (state): string => state.user.user_login,
    capability: (state): Capability => state.user.capability,
    isLogin: (state): boolean => state.user.isLogin,
    isAdmin: (state): boolean => state.user.isAdmin,
    user_type: (state): string => state.user.user_type,
    isLicenseOwner: (state): boolean => state.user.isLicenseOwner, // 라이센스 소유자 여부 (추가: 김정현, 2024-08-29)
    user_status: (state): any => state.user.user_status,
    licenseInfo: (state): LicenseInfo => state.license_info, // 라이센스 정보 (추가: 김정현, 2024-11-19)
    licenseProductName: (state): string => state.license_info.product.productName || 'Free 멤버십', // 라이센스 정보 중 product name (추가: 김정현, 2024-11-19)
    licenseDuration: (state): string => {
      if (state.user.isLicenseOwner) {
        const start = state.license_info.startDatetime.split(' ')[0];
        const end = state.license_info.endDatetime.split(' ')[0];
        const licenseDuration = `${start} ~ ${end}`;
        return licenseDuration;
      } 
      return '-';
    }
  },
  actions: {
    async setUser(data: IUser) {
      this.$patch((state) => {
        state.user = data;
        state.user.isLogin = true;
        state.user.isAdmin = data.capability.value === 'administrator';
      });

      try {
        // 사용자 라이센스 정보를 가져옵니다.
        const license_res = await getUserLicense();

        if(license_res.data !== '') {
          this.$patch((state) => {
            state.license_info = license_res.data;
          });
          this.setLicenseOwner(true);
        } else {
          this.$patch((state) => {
            state.license_info = {
              licenseId: 0,
              licenseCode: '',
              licenseDescription: '',
              licenseStatus: '',
              licenseEmail: '',
              startDatetime: '',
              endDatetime: '',
              product: {
                productName: '',
                id: 0,
                productDescription: null,
                productUnit: 0,
                productLevel: '',
                productType: '',
                productPrice: null,
                productStatus: '',
                licenseCapacity: 0
              },
            };
          });
          this.setLicenseOwner(false);
        }
      } catch (error) {
        console.error(error);
        throw error; // 호출자에게 오류를 전달합니다.
      }
    },
    resetUser() {
      useJajakToken().value = null; // 토큰 초기화
      this.$reset(); // auth 정보 초기화
    },
    setLicenseOwner(isLicenseOwner: boolean) { // 라이센스 소유자 여부 (추가: 김정현, 2024-08-29)
      this.$patch((state) => {
        state.user.isLicenseOwner = isLicenseOwner;
      });
    }
  },
})

if (import.meta.hot) {  //HMR
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
