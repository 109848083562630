//채널톡 부팅
export const boot = (auth: any) => {
  let unsubscribed = false;
  (window as any).ChannelIO('boot', {
    pluginKey: useRuntimeConfig().public.channelTalkKey,
    memberId: auth.user_login ?? null,
    unsubscribed: false,
    profile: {
      name: auth.pen_name ?? auth.user_id ?? null, // 닉네임이 없는 경우 아이디로 대체
      mobileNumber: auth.user_tel ?? null,
      email: auth.user_email ?? null,
      status: auth.user_classify ?? null,
      roomNo: auth.room_no ?? null,
      userType: auth.user_types ?? null,
      numbering: auth.user_id ?? null,
      unsubscribed: false,
    },
  })
}

export const shutdown = () => {
  (window as any).ChannelIO('shutdown')
}

export const openChannelTalk = () => {
  (window as any).ChannelIO('open')
}

export const showChannelTalk = () => {
  (window as any).ChannelIO('show')
}
export const openChat = () => {
  (window as any).ChannelIO('openChat')
}
